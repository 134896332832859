.App { /* Base font style */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 15.1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.8500);
  background-color: #f6f6f6;
}

.App .baseFont * { /* Base font style override for UI framework components */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 15.1px;
}

.App .appBg {
  background-color: #f6f6f6;
}

.App .cardBg {
  background-color: white;
}

.App .baseTextBg { /* Base text color as background */
  background-color: rgba(0, 0, 0, 0.8500);
}

.App .secondaryTextColor {
  color: rgba(0, 0, 0, 0.5000);
}

.App .secondaryTextBg {
  background-color: rgba(0, 0, 0, 0.5000);
}

.App .primaryTextColor {
  color: #57c8b8;
}

.App .primaryBg {
  background-color: #57c8b8;
}

.App .highlightTextColor {
  color: #65d0c4;
}

.App .highlightBg {
  background-color: #65d0c4;
}

@media (display-mode: standalone) {
  .App {
    user-select: none;
  }
}

/* Styles to customize the UI framework plugin (MUI, https://muicss.com) */
.mui-btn {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}
.mui-btn--primary {
    background-color: #57c8b8;
}
.mui-btn--primary:active, .mui-btn--primary:focus, .mui-btn--primary:hover {
    background-color: #63cbbf;
}
.mui-btn--accent {
    background-color: #65d0c4;
}
.mui-btn--accent:active, .mui-btn--accent:focus, .mui-btn--accent:hover {
    background-color: #70d3ca;
}
.mui-textfield {  /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}
.mui-checkbox, .mui-radio {  /* Reset UI framework styling that affects layout */
    margin-top: 0;
    margin-bottom: 0;
}
.mui-select {  /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}
.mui-btn+.mui-btn {  /* Reset UI framework styling that affects layout */
    margin-left: 0;
}
.mui-btn--fab {  /* Reset UI framework styling that breaks compositing */
    z-index: auto;
}

@keyframes fadeIn {
    0%   { opacity: 0.0; }
    100% { opacity: 1.0; }
}
@keyframes slideIn_right {
    0%   { transform: translateX(100%); }
    100% { transform: translateX(0); }
}
@keyframes slideIn_bottom {
    0%   { transform: translateY(100%); }
    100% { transform: translateY(0); }
}

/* System font classes */

.App .systemFontRegular {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}
.App .systemFontBold {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
}
.App .systemFontItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-style: italic;
}
.App .systemFontBoldItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
  font-style: italic;
}

/* App-wide text styles specified in React Studio */

.App .actionFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 14.2px;
  font-weight: 400;
}
.App .actionFont * {
  font-size: 14.2px;
}
.App .headlineFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 19.4px;
  font-weight: 400;
}
.App .headlineFont * {
  font-size: 19.4px;
}
.App .navBar {
  background-color: #57c8b8;
  color: #fcffff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  min-height: 50px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 18px;
}

.App .navBar a {
  color: #fcffff;
}

.App .navBar .title {
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 4px;
}

.App .navBar .backBtn {
  position: absolute;
  padding: 4px;
  margin-left: 14px;
  cursor: pointer;
}

.App .navBar > div {
  margin-top: 7px;
}

@media (min-width: 568px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 768px) {
  .App .navBar {
    height: 75px;
    min-height: 75px;
  }
  .App .navBar > div {
    margin-top: 19px;
  }
}
@media (min-width: 1024px) {
  .App .navBar {
    height: 75px;
    min-height: 75px;
  }
  .App .navBar > div {
    margin-top: 19px;
  }
}
@media (min-width: 320px) {
  .mui-container {
    max-width: 320px;
  }
}
@media (min-width: 568px) {
  .mui-container {
    max-width: 568px;
  }
}
@media (min-width: 768px) {
  .mui-container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .mui-container {
    max-width: 1024px;
  }
}

.AppScreen {
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.App .background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App .layoutFlow {
  position: relative;
  width: 100%;
  pointer-events: none;
}
.App .layoutFlow:before {  /* Prevent margin collapse */
  content: "";
  display: table;
  clear: both;
}
.App .layoutFlow .flowRow {
  position: relative;
  width: 100%;
}

.App .screenFgContainer {
  /* Items in the foreground group within a screen are placed with fixed positioning */
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.App .foreground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App ul {
  margin: 0;
  padding: 0;
}
.App li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
}
.App .hasNestedComps {
  position: relative;
}
.App .hasNestedComps:after {
  content: "";
  display: table;
  clear: both;
}
.App .hasNestedComps .background {
  position: absolute;
}
.App .hasNestedComps .layoutFlow {
  position: relative;
}
.App .bottomAlignedContent {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.App .blockInputOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: auto;
  z-index: 99999999;
}

.App .marker {
  width: 0px;
  height: 0px;
}

.App .drawer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.App .drawerOpen {
  transform: translateX(0%);
  transition: 0.3s;
}

.App .drawerClosedLeft {
  transform: translateX(-100%);
  transition: 0.3s;
}

.App .drawerClosedRight {
  transform: translateX(100%);
  transition: 0.3s;
}

.App .drawerContent {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

.App .drawerContent > * {
  height: 100%;
}


/* Narrowest size for this element's responsive layout */
  .NeurowasteScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .NeurowasteScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .NeurowasteScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .NeurowasteScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .NeurowasteScreen > .screenFgContainer > .foreground > .elScanner {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
@media (min-width: 568px) {
  .NeurowasteScreen > .screenFgContainer > .foreground > .elScanner {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
}
@media (min-width: 768px) {
  .NeurowasteScreen > .screenFgContainer > .foreground > .elScanner {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
}
@media (min-width: 1024px) {
  .NeurowasteScreen > .screenFgContainer > .foreground > .elScanner {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
}

.NeurowasteScreen .elCapture {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.NeurowasteScreen .elCapture:focus {
  box-shadow: 0 0 5px rgba(96, 206, 196, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .NeurowasteScreen > .screenFgContainer > .foreground > .elCapture {
    line-height: 35px;
    position: fixed;
    left: calc(50.0% + 0px - 100px);
    bottom: 10px;
    width: 200px;
    height: 35px;
  }
@media (min-width: 568px) {
  .NeurowasteScreen > .screenFgContainer > .foreground > .elCapture {
    line-height: 35px;
    left: calc(50.0% + 0px - 100px);
    bottom: 10px;
    width: 200px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .NeurowasteScreen > .screenFgContainer > .foreground > .elCapture {
    line-height: 35px;
    left: calc(50.0% + 0px - 99px);
    bottom: 10px;
    width: 199px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .NeurowasteScreen > .screenFgContainer > .foreground > .elCapture {
    line-height: 35px;
    left: calc(50.0% + 0px - 99px);
    bottom: 10px;
    width: 199px;
    height: 35px;
  }
}

.NeurowasteScreen .elWait {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.NeurowasteScreen .elWait:focus {
  box-shadow: 0 0 6px rgba(96, 206, 196, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .NeurowasteScreen > .screenFgContainer > .foreground > .elWait {
    position: absolute;
    left: calc(50.0% + 0px - 25px);
    top: 200px;
    width: 50px;
    height: 50px;
  }
@media (min-width: 568px) {
  .NeurowasteScreen > .screenFgContainer > .foreground > .elWait {
    left: calc(50.0% + 0px - 25px);
    top: 200px;
    width: 50px;
    height: 50px;
  }
}
@media (min-width: 768px) {
  .NeurowasteScreen > .screenFgContainer > .foreground > .elWait {
    left: calc(50.0% + 0px - 25px);
    top: 199px;
    width: 50px;
    height: 50px;
  }
}
@media (min-width: 1024px) {
  .NeurowasteScreen > .screenFgContainer > .foreground > .elWait {
    left: calc(50.0% + 0px - 25px);
    top: 199px;
    width: 50px;
    height: 50px;
  }
}


/* Narrowest size for this element's responsive layout */
  .NeurowasteScreen > .screenFgContainer > .foreground > .elImage {
    position: absolute;
    left: calc(50.0% + 0px - 95px);
    top: 4px;
    width: 190px;
    height: 40px;
  }
@media (min-width: 568px) {
  .NeurowasteScreen > .screenFgContainer > .foreground > .elImage {
    left: calc(50.0% + 0px - 95px);
    top: 4px;
    width: 190px;
    height: 40px;
  }
}
@media (min-width: 768px) {
  .NeurowasteScreen > .screenFgContainer > .foreground > .elImage {
    left: calc(50.0% + 0px - 94px);
    top: 4px;
    width: 189px;
    height: 40px;
  }
}
@media (min-width: 1024px) {
  .NeurowasteScreen > .screenFgContainer > .foreground > .elImage {
    left: calc(50.0% + 0px - 94px);
    top: 4px;
    width: 189px;
    height: 40px;
  }
}


/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .ConteneurScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .ConteneurScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .ConteneurScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .ConteneurScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .ConteneurScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .ConteneurScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .ConteneurScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 15px;
    margin-left: 0px;
  }
  .ConteneurScreen > .layoutFlow > .elSpacer > * { 
  width: 15px;
  height: 75px;
  }
  
}
@media (min-width: 1024px) {
  .ConteneurScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 15px;
    margin-left: 0px;
  }
  .ConteneurScreen > .layoutFlow > .elSpacer > * { 
  width: 15px;
  height: 75px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConteneurScreen > .layoutFlow > .elText > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ConteneurScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConteneurScreen > .layoutFlow > .elText > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ConteneurScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConteneurScreen > .layoutFlow > .elText > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ConteneurScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConteneurScreen > .layoutFlow > .elText > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .layoutFlow > .elTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConteneurScreen > .layoutFlow > .elTextCopy > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ConteneurScreen > .layoutFlow > .elTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConteneurScreen > .layoutFlow > .elTextCopy > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ConteneurScreen > .layoutFlow > .elTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConteneurScreen > .layoutFlow > .elTextCopy > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ConteneurScreen > .layoutFlow > .elTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConteneurScreen > .layoutFlow > .elTextCopy > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .layoutFlow > .elScanned_id {
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConteneurScreen > .layoutFlow > .elScanned_id > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ConteneurScreen > .layoutFlow > .elScanned_id {
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConteneurScreen > .layoutFlow > .elScanned_id > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ConteneurScreen > .layoutFlow > .elScanned_id {
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConteneurScreen > .layoutFlow > .elScanned_id > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ConteneurScreen > .layoutFlow > .elScanned_id {
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConteneurScreen > .layoutFlow > .elScanned_id > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .layoutFlow > .elRectangleCopy2 {
    position: relative;
    margin-top: 20px;
    width: 250px;
    margin-left: calc(50.0% - 125px);
  }
  .ConteneurScreen > .layoutFlow > .elRectangleCopy2 > * { 
  width: 250px;
  height: 1px;
  }
  
@media (min-width: 568px) {
  .ConteneurScreen > .layoutFlow > .elRectangleCopy2 {
    position: relative;
    margin-top: 20px;
    width: 250px;
    margin-left: calc(50.0% - 125px);
  }
  .ConteneurScreen > .layoutFlow > .elRectangleCopy2 > * { 
  width: 250px;
  height: 1px;
  }
  
}
@media (min-width: 768px) {
  .ConteneurScreen > .layoutFlow > .elRectangleCopy2 {
    position: relative;
    margin-top: 20px;
    width: 248px;
    margin-left: calc(50.0% - 124px);
  }
  .ConteneurScreen > .layoutFlow > .elRectangleCopy2 > * { 
  width: 248px;
  height: 1px;
  }
  
}
@media (min-width: 1024px) {
  .ConteneurScreen > .layoutFlow > .elRectangleCopy2 {
    position: relative;
    margin-top: 20px;
    width: 248px;
    margin-left: calc(50.0% - 124px);
  }
  .ConteneurScreen > .layoutFlow > .elRectangleCopy2 > * { 
  width: 248px;
  height: 1px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .layoutFlow > .elTextCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConteneurScreen > .layoutFlow > .elTextCopy2 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ConteneurScreen > .layoutFlow > .elTextCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConteneurScreen > .layoutFlow > .elTextCopy2 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ConteneurScreen > .layoutFlow > .elTextCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConteneurScreen > .layoutFlow > .elTextCopy2 > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ConteneurScreen > .layoutFlow > .elTextCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConteneurScreen > .layoutFlow > .elTextCopy2 > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .layoutFlow > .elRectangleCopy3 {
    position: relative;
    margin-top: 20px;
    width: 250px;
    margin-left: calc(50.0% - 125px);
  }
  .ConteneurScreen > .layoutFlow > .elRectangleCopy3 > * { 
  width: 250px;
  height: 1px;
  }
  
@media (min-width: 568px) {
  .ConteneurScreen > .layoutFlow > .elRectangleCopy3 {
    position: relative;
    margin-top: 20px;
    width: 250px;
    margin-left: calc(50.0% - 125px);
  }
  .ConteneurScreen > .layoutFlow > .elRectangleCopy3 > * { 
  width: 250px;
  height: 1px;
  }
  
}
@media (min-width: 768px) {
  .ConteneurScreen > .layoutFlow > .elRectangleCopy3 {
    position: relative;
    margin-top: 20px;
    width: 248px;
    margin-left: calc(50.0% - 124px);
  }
  .ConteneurScreen > .layoutFlow > .elRectangleCopy3 > * { 
  width: 248px;
  height: 1px;
  }
  
}
@media (min-width: 1024px) {
  .ConteneurScreen > .layoutFlow > .elRectangleCopy3 {
    position: relative;
    margin-top: 20px;
    width: 248px;
    margin-left: calc(50.0% - 124px);
  }
  .ConteneurScreen > .layoutFlow > .elRectangleCopy3 > * { 
  width: 248px;
  height: 1px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy3 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy3 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy3 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy3 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy3 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy3 > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy3 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy3 > * { 
  width: 236px;
  }
  
}

.ConteneurScreen .elImage {
  overflow: hidden;
  pointer-events: auto;
}

.ConteneurScreen .elImage.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .layoutFlow > .elImage {
    position: relative;
    margin-top: 10px;
    width: 100%;
  }
  .ConteneurScreen > .layoutFlow > .elImage > * { 
  margin-left: auto;
  width: 109px;
  margin-right: 50.0%;
  }
  
  .ConteneurScreen > .layoutFlow > .elImage > .background > .containerMinHeight {
    min-height: 134px;
  }

@media (min-width: 568px) {
  .ConteneurScreen > .layoutFlow > .elImage {
    position: relative;
    margin-top: 10px;
    width: 100%;
  }
  .ConteneurScreen > .layoutFlow > .elImage > * { 
  margin-left: auto;
  width: 109px;
  margin-right: 50.0%;
  }
  
  .ConteneurScreen > .layoutFlow > .elImage > .background > .containerMinHeight {
    min-height: 134px;
  }

}
@media (min-width: 768px) {
  .ConteneurScreen > .layoutFlow > .elImage {
    position: relative;
    margin-top: 10px;
    width: 100%;
  }
  .ConteneurScreen > .layoutFlow > .elImage > * { 
  margin-left: auto;
  width: 108px;
  margin-right: 50.0%;
  }
  
  .ConteneurScreen > .layoutFlow > .elImage > .background > .containerMinHeight {
    min-height: 133px;
  }

}
@media (min-width: 1024px) {
  .ConteneurScreen > .layoutFlow > .elImage {
    position: relative;
    margin-top: 10px;
    width: 100%;
  }
  .ConteneurScreen > .layoutFlow > .elImage > * { 
  margin-left: auto;
  width: 108px;
  margin-right: 50.0%;
  }
  
  .ConteneurScreen > .layoutFlow > .elImage > .background > .containerMinHeight {
    min-height: 133px;
  }

}

.ConteneurScreen .elLevel {
  overflow: hidden;
  pointer-events: auto;
}

.ConteneurScreen .elLevel.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .layoutFlow > .elLevel {
    position: relative;
    margin-top: -114px;
    width: 20px;
    margin-left: calc(50.0% + 20px);
  }
  .ConteneurScreen > .layoutFlow > .elLevel > * { 
  width: 20px;
  }
  
  .ConteneurScreen > .layoutFlow > .elLevel > .background > .containerMinHeight {
    min-height: 105px;
  }

@media (min-width: 568px) {
  .ConteneurScreen > .layoutFlow > .elLevel {
    position: relative;
    margin-top: -114px;
    width: 20px;
    margin-left: calc(50.0% + 20px);
  }
  .ConteneurScreen > .layoutFlow > .elLevel > * { 
  width: 20px;
  }
  
  .ConteneurScreen > .layoutFlow > .elLevel > .background > .containerMinHeight {
    min-height: 105px;
  }

}
@media (min-width: 768px) {
  .ConteneurScreen > .layoutFlow > .elLevel {
    position: relative;
    margin-top: -114px;
    width: 20px;
    margin-left: calc(50.0% + 20px);
  }
  .ConteneurScreen > .layoutFlow > .elLevel > * { 
  width: 20px;
  }
  
  .ConteneurScreen > .layoutFlow > .elLevel > .background > .containerMinHeight {
    min-height: 105px;
  }

}
@media (min-width: 1024px) {
  .ConteneurScreen > .layoutFlow > .elLevel {
    position: relative;
    margin-top: -114px;
    width: 20px;
    margin-left: calc(50.0% + 20px);
  }
  .ConteneurScreen > .layoutFlow > .elLevel > * { 
  width: 20px;
  }
  
  .ConteneurScreen > .layoutFlow > .elLevel > .background > .containerMinHeight {
    min-height: 105px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 195px;
  }
  .ConteneurScreen > .layoutFlow > .elSpacer3 > * { 
  width: 10px;
  height: 15px;
  }
  
@media (min-width: 568px) {
  .ConteneurScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 195px;
  }
  .ConteneurScreen > .layoutFlow > .elSpacer3 > * { 
  width: 10px;
  height: 15px;
  }
  
}
@media (min-width: 768px) {
  .ConteneurScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 15px;
    margin-left: 194px;
  }
  .ConteneurScreen > .layoutFlow > .elSpacer3 > * { 
  width: 15px;
  height: 22px;
  }
  
}
@media (min-width: 1024px) {
  .ConteneurScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 15px;
    margin-left: 194px;
  }
  .ConteneurScreen > .layoutFlow > .elSpacer3 > * { 
  width: 15px;
  height: 22px;
  }
  
}

.ConteneurScreen .elComp {
  overflow: hidden;
  pointer-events: auto;
}

.ConteneurScreen .elComp.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .layoutFlow > .elComp {
    position: relative;
    margin-top: 10px;
    width: 229px;
    margin-left: calc(50.0% - 114px);
  }
  .ConteneurScreen > .layoutFlow > .elComp > * { 
  width: 229px;
  }
  
  .ConteneurScreen > .layoutFlow > .elComp > .background > .containerMinHeight {
    min-height: 71px;
  }

@media (min-width: 568px) {
  .ConteneurScreen > .layoutFlow > .elComp {
    position: relative;
    margin-top: 10px;
    width: 229px;
    margin-left: calc(50.0% - 114px);
  }
  .ConteneurScreen > .layoutFlow > .elComp > * { 
  width: 229px;
  }
  
  .ConteneurScreen > .layoutFlow > .elComp > .background > .containerMinHeight {
    min-height: 71px;
  }

}
@media (min-width: 768px) {
  .ConteneurScreen > .layoutFlow > .elComp {
    position: relative;
    margin-top: 10px;
    width: 228px;
    margin-left: calc(50.0% - 114px);
  }
  .ConteneurScreen > .layoutFlow > .elComp > * { 
  width: 228px;
  }
  
  .ConteneurScreen > .layoutFlow > .elComp > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .ConteneurScreen > .layoutFlow > .elComp {
    position: relative;
    margin-top: 10px;
    width: 228px;
    margin-left: calc(50.0% - 114px);
  }
  .ConteneurScreen > .layoutFlow > .elComp > * { 
  width: 228px;
  }
  
  .ConteneurScreen > .layoutFlow > .elComp > .background > .containerMinHeight {
    min-height: 70px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 20px;
    width: 250px;
    margin-left: calc(50.0% - 125px);
  }
  .ConteneurScreen > .layoutFlow > .elRectangle > * { 
  width: 250px;
  height: 1px;
  }
  
@media (min-width: 568px) {
  .ConteneurScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 20px;
    width: 250px;
    margin-left: calc(50.0% - 125px);
  }
  .ConteneurScreen > .layoutFlow > .elRectangle > * { 
  width: 250px;
  height: 1px;
  }
  
}
@media (min-width: 768px) {
  .ConteneurScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 20px;
    width: 248px;
    margin-left: calc(50.0% - 124px);
  }
  .ConteneurScreen > .layoutFlow > .elRectangle > * { 
  width: 248px;
  height: 1px;
  }
  
}
@media (min-width: 1024px) {
  .ConteneurScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 20px;
    width: 248px;
    margin-left: calc(50.0% - 124px);
  }
  .ConteneurScreen > .layoutFlow > .elRectangle > * { 
  width: 248px;
  height: 1px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .layoutFlow > .elAnomalies2 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConteneurScreen > .layoutFlow > .elAnomalies2 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ConteneurScreen > .layoutFlow > .elAnomalies2 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConteneurScreen > .layoutFlow > .elAnomalies2 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ConteneurScreen > .layoutFlow > .elAnomalies2 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConteneurScreen > .layoutFlow > .elAnomalies2 > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ConteneurScreen > .layoutFlow > .elAnomalies2 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConteneurScreen > .layoutFlow > .elAnomalies2 > * { 
  width: 236px;
  }
  
}

.ConteneurScreen .elList2 li {
  margin-bottom: 5px;
}



/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .layoutFlow > .elList2 {
    position: relative;
    margin-top: 20px;
    width: 250px;
    margin-left: calc(50.0% - 125px);
  }
  .ConteneurScreen > .layoutFlow > .elList2 > * { 
  width: 250px;
  }
  
@media (min-width: 568px) {
  .ConteneurScreen > .layoutFlow > .elList2 {
    position: relative;
    margin-top: 20px;
    width: 250px;
    margin-left: calc(50.0% - 125px);
  }
  .ConteneurScreen > .layoutFlow > .elList2 > * { 
  width: 250px;
  }
  
}
@media (min-width: 768px) {
  .ConteneurScreen > .layoutFlow > .elList2 {
    position: relative;
    margin-top: 20px;
    width: 248px;
    margin-left: calc(50.0% - 124px);
  }
  .ConteneurScreen > .layoutFlow > .elList2 > * { 
  width: 248px;
  }
  
}
@media (min-width: 1024px) {
  .ConteneurScreen > .layoutFlow > .elList2 {
    position: relative;
    margin-top: 20px;
    width: 248px;
    margin-left: calc(50.0% - 124px);
  }
  .ConteneurScreen > .layoutFlow > .elList2 > * { 
  width: 248px;
  }
  
}

.ConteneurScreen .elCaptureCopy button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.ConteneurScreen .elCaptureCopy button:focus {
  box-shadow: 0 0 6px rgba(96, 206, 196, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .layoutFlow > .elCaptureCopy {
    position: relative;
    margin-top: 10px;
    width: 150px;
    margin-left: calc(50.0% - 75px);
  }
  .ConteneurScreen > .layoutFlow > .elCaptureCopy > * { 
  width: 150px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .ConteneurScreen > .layoutFlow > .elCaptureCopy {
    position: relative;
    margin-top: 10px;
    width: 150px;
    margin-left: calc(50.0% - 75px);
  }
  .ConteneurScreen > .layoutFlow > .elCaptureCopy > * { 
  width: 150px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .ConteneurScreen > .layoutFlow > .elCaptureCopy {
    position: relative;
    margin-top: 10px;
    width: 149px;
    margin-left: calc(50.0% - 74px);
  }
  .ConteneurScreen > .layoutFlow > .elCaptureCopy > * { 
  width: 149px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .ConteneurScreen > .layoutFlow > .elCaptureCopy {
    position: relative;
    margin-top: 10px;
    width: 149px;
    margin-left: calc(50.0% - 74px);
  }
  .ConteneurScreen > .layoutFlow > .elCaptureCopy > * { 
  width: 149px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .layoutFlow > .elImageCopy {
    position: relative;
    margin-top: 10px;
    width: 300px;
    margin-left: calc(50.0% - 150px);
  }
  .ConteneurScreen > .layoutFlow > .elImageCopy > * { 
  width: 300px;
  height: 100px;
  }
  
@media (min-width: 568px) {
  .ConteneurScreen > .layoutFlow > .elImageCopy {
    position: relative;
    margin-top: 10px;
    width: 300px;
    margin-left: calc(50.0% - 150px);
  }
  .ConteneurScreen > .layoutFlow > .elImageCopy > * { 
  width: 300px;
  height: 100px;
  }
  
}
@media (min-width: 768px) {
  .ConteneurScreen > .layoutFlow > .elImageCopy {
    position: relative;
    margin-top: 10px;
    width: 298px;
    margin-left: calc(50.0% - 149px);
  }
  .ConteneurScreen > .layoutFlow > .elImageCopy > * { 
  width: 298px;
  height: 100px;
  }
  
}
@media (min-width: 1024px) {
  .ConteneurScreen > .layoutFlow > .elImageCopy {
    position: relative;
    margin-top: 10px;
    width: 298px;
    margin-left: calc(50.0% - 149px);
  }
  .ConteneurScreen > .layoutFlow > .elImageCopy > * { 
  width: 298px;
  height: 100px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .layoutFlow > .elRectangleCopy {
    position: relative;
    margin-top: 20px;
    width: 250px;
    margin-left: calc(50.0% - 125px);
  }
  .ConteneurScreen > .layoutFlow > .elRectangleCopy > * { 
  width: 250px;
  height: 1px;
  }
  
@media (min-width: 568px) {
  .ConteneurScreen > .layoutFlow > .elRectangleCopy {
    position: relative;
    margin-top: 20px;
    width: 250px;
    margin-left: calc(50.0% - 125px);
  }
  .ConteneurScreen > .layoutFlow > .elRectangleCopy > * { 
  width: 250px;
  height: 1px;
  }
  
}
@media (min-width: 768px) {
  .ConteneurScreen > .layoutFlow > .elRectangleCopy {
    position: relative;
    margin-top: 20px;
    width: 248px;
    margin-left: calc(50.0% - 124px);
  }
  .ConteneurScreen > .layoutFlow > .elRectangleCopy > * { 
  width: 248px;
  height: 1px;
  }
  
}
@media (min-width: 1024px) {
  .ConteneurScreen > .layoutFlow > .elRectangleCopy {
    position: relative;
    margin-top: 20px;
    width: 248px;
    margin-left: calc(50.0% - 124px);
  }
  .ConteneurScreen > .layoutFlow > .elRectangleCopy > * { 
  width: 248px;
  height: 1px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy2 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy2 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy2 > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConteneurScreen > .layoutFlow > .elAnomaliesCopy2 > * { 
  width: 236px;
  }
  
}

.ConteneurScreen .elList > div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 5px;
  grid-row-gap: 5px;  /* old name still required by some browsers */
  column-gap: 5px;
  grid-column-gap: 5px;  /* old name still required by some browsers */
}

.ConteneurScreen .elList > div > * {
  display: inline-block;
  position: relative;
  min-width: 80px;
}

  @media (min-width: 1024px) {
    .ConteneurScreen .elList > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .ConteneurScreen .elList > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 568px) and (max-width: 767px) {
    .ConteneurScreen .elList > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  


/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 20px;
    width: 250px;
    margin-left: calc(50.0% - 125px);
  }
  .ConteneurScreen > .layoutFlow > .elList > * { 
  width: 250px;
  }
  
@media (min-width: 568px) {
  .ConteneurScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 20px;
    width: 250px;
    margin-left: calc(50.0% - 125px);
  }
  .ConteneurScreen > .layoutFlow > .elList > * { 
  width: 250px;
  }
  
}
@media (min-width: 768px) {
  .ConteneurScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 20px;
    width: 248px;
    margin-left: calc(50.0% - 124px);
  }
  .ConteneurScreen > .layoutFlow > .elList > * { 
  width: 248px;
  }
  
}
@media (min-width: 1024px) {
  .ConteneurScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 20px;
    width: 248px;
    margin-left: calc(50.0% - 124px);
  }
  .ConteneurScreen > .layoutFlow > .elList > * { 
  width: 248px;
  }
  
}

.ConteneurScreen .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.ConteneurScreen .elButton button:focus {
  box-shadow: 0 0 5px rgba(96, 206, 196, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .ConteneurScreen > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .ConteneurScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .ConteneurScreen > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .ConteneurScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .ConteneurScreen > .layoutFlow > .elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .ConteneurScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .ConteneurScreen > .layoutFlow > .elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: calc(50.0% - 5px);
  }
  .ConteneurScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 60px;
  }
  
@media (min-width: 568px) {
  .ConteneurScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: calc(50.0% - 5px);
  }
  .ConteneurScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 60px;
  }
  
}
@media (min-width: 768px) {
  .ConteneurScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 15px;
    margin-left: calc(50.0% - 8px);
  }
  .ConteneurScreen > .layoutFlow > .elSpacer2 > * { 
  width: 15px;
  height: 90px;
  }
  
}
@media (min-width: 1024px) {
  .ConteneurScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 15px;
    margin-left: calc(50.0% - 8px);
  }
  .ConteneurScreen > .layoutFlow > .elSpacer2 > * { 
  width: 15px;
  height: 90px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConteneurScreen > .screenFgContainer > .foreground > .elBig_image {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 60px;
    width: 100.0%;
    height: calc(100% + 0.0% + -60.0px);
  }
@media (min-width: 568px) {
  .ConteneurScreen > .screenFgContainer > .foreground > .elBig_image {
    left: 0px;
    right: 0px;
    top: 60px;
    width: 100.0%;
    height: calc(100% + 0.0% + -60.0px);
  }
}
@media (min-width: 768px) {
  .ConteneurScreen > .screenFgContainer > .foreground > .elBig_image {
    left: 0px;
    right: 0px;
    top: 60px;
    width: 100.0%;
    height: calc(100% + 0.0% + -59.6px);
  }
}
@media (min-width: 1024px) {
  .ConteneurScreen > .screenFgContainer > .foreground > .elBig_image {
    left: 0px;
    right: 0px;
    top: 60px;
    width: 100.0%;
    height: calc(100% + 0.0% + -59.6px);
  }
}


/* Narrowest size for this element's responsive layout */
  .AppareilPhotoScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .AppareilPhotoScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .AppareilPhotoScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .AppareilPhotoScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .AppareilPhotoScreen > .screenFgContainer > .foreground > .elCamera {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
@media (min-width: 568px) {
  .AppareilPhotoScreen > .screenFgContainer > .foreground > .elCamera {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
}
@media (min-width: 768px) {
  .AppareilPhotoScreen > .screenFgContainer > .foreground > .elCamera {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
}
@media (min-width: 1024px) {
  .AppareilPhotoScreen > .screenFgContainer > .foreground > .elCamera {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
}

.AppareilPhotoScreen .elCapture_camera {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.AppareilPhotoScreen .elCapture_camera:focus {
  box-shadow: 0 0 6px rgba(96, 206, 196, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .AppareilPhotoScreen > .screenFgContainer > .foreground > .elCapture_camera {
    position: fixed;
    left: calc(50.0% + 0px - 50px);
    bottom: 10px;
    width: 100px;
    height: 36px;
  }
@media (min-width: 568px) {
  .AppareilPhotoScreen > .screenFgContainer > .foreground > .elCapture_camera {
    left: calc(50.0% + 0px - 50px);
    bottom: 10px;
    width: 100px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .AppareilPhotoScreen > .screenFgContainer > .foreground > .elCapture_camera {
    left: calc(50.0% + 0px - 50px);
    bottom: 10px;
    width: 99px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .AppareilPhotoScreen > .screenFgContainer > .foreground > .elCapture_camera {
    left: calc(50.0% + 0px - 50px);
    bottom: 10px;
    width: 99px;
    height: 36px;
  }
}

/* This component has a fixed-size layout */
  .Level {
    min-height: 105px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 20px */
    height: 100%;
  }
  .Level > .background > .containerMinHeight {
    min-height: 105px;
  }


  .Level > .foreground > .state0_elRectangleCopy3 {
    position: absolute;
    left: 0px;
    top: 80px;
    width: 20px;
    height: 20px;
  }


  .Level > .foreground > .state0_elRectangleCopy216603 {
    position: absolute;
    left: 0px;
    top: 55px;
    width: 20px;
    height: 20px;
  }


  .Level > .foreground > .state0_elRectangleCopy760407 {
    position: absolute;
    left: 0px;
    top: 30px;
    width: 20px;
    height: 20px;
  }


  .Level > .foreground > .state0_elRectangle720604 {
    position: absolute;
    left: 0px;
    top: 5px;
    width: 20px;
    height: 20px;
  }

/* This component has a fixed-size layout */
  .Level {
    min-height: 105px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 20px */
    height: 100%;
  }
  .Level > .background > .containerMinHeight {
    min-height: 105px;
  }


  .Level > .foreground > .state1_elRectangleCopy3684617 {
    position: absolute;
    left: 0px;
    top: 80px;
    width: 20px;
    height: 20px;
  }


  .Level > .foreground > .state1_elRectangleCopy216603 {
    position: absolute;
    left: 0px;
    top: 55px;
    width: 20px;
    height: 20px;
  }


  .Level > .foreground > .state1_elRectangleCopy760407 {
    position: absolute;
    left: 0px;
    top: 30px;
    width: 20px;
    height: 20px;
  }


  .Level > .foreground > .state1_elRectangle720604 {
    position: absolute;
    left: 0px;
    top: 5px;
    width: 20px;
    height: 20px;
  }

/* This component has a fixed-size layout */
  .Level {
    min-height: 105px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 20px */
    height: 100%;
  }
  .Level > .background > .containerMinHeight {
    min-height: 105px;
  }


  .Level > .foreground > .state2_elRectangleCopy3684617 {
    position: absolute;
    left: 0px;
    top: 80px;
    width: 20px;
    height: 20px;
  }


  .Level > .foreground > .state2_elRectangleCopy2850173 {
    position: absolute;
    left: 0px;
    top: 55px;
    width: 20px;
    height: 20px;
  }


  .Level > .foreground > .state2_elRectangleCopy760407 {
    position: absolute;
    left: 0px;
    top: 30px;
    width: 20px;
    height: 20px;
  }


  .Level > .foreground > .state2_elRectangle720604 {
    position: absolute;
    left: 0px;
    top: 5px;
    width: 20px;
    height: 20px;
  }

/* This component has a fixed-size layout */
  .Level {
    min-height: 105px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 20px */
    height: 100%;
  }
  .Level > .background > .containerMinHeight {
    min-height: 105px;
  }


  .Level > .foreground > .state3_elRectangleCopy3684617 {
    position: absolute;
    left: 0px;
    top: 80px;
    width: 20px;
    height: 20px;
  }


  .Level > .foreground > .state3_elRectangleCopy2850173 {
    position: absolute;
    left: 0px;
    top: 55px;
    width: 20px;
    height: 20px;
  }


  .Level > .foreground > .state3_elRectangleCopy417639 {
    position: absolute;
    left: 0px;
    top: 30px;
    width: 20px;
    height: 20px;
  }


  .Level > .foreground > .state3_elRectangle720604 {
    position: absolute;
    left: 0px;
    top: 5px;
    width: 20px;
    height: 20px;
  }

/* This component has a fixed-size layout */
  .Level {
    min-height: 105px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 20px */
    height: 100%;
  }
  .Level > .background > .containerMinHeight {
    min-height: 105px;
  }


  .Level > .foreground > .state4_elRectangleCopy3684617 {
    position: absolute;
    left: 0px;
    top: 80px;
    width: 20px;
    height: 20px;
  }


  .Level > .foreground > .state4_elRectangleCopy2850173 {
    position: absolute;
    left: 0px;
    top: 55px;
    width: 20px;
    height: 20px;
  }


  .Level > .foreground > .state4_elRectangleCopy417639 {
    position: absolute;
    left: 0px;
    top: 30px;
    width: 20px;
    height: 20px;
  }


  .Level > .foreground > .state4_elRectangle {
    position: absolute;
    left: 0px;
    top: 5px;
    width: 20px;
    height: 20px;
  }

/* This component has a fixed-size layout */
  .Tonnage_volume {
    width: 100%; /* This component was designed using a width of 229px */
    height: 100%;
  }
  .Tonnage_volume > .background > .containerMinHeight {
    min-height: 71px;
  }


  .Tonnage_volume > .layoutFlow > .elFieldCopy3 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Tonnage_volume > .layoutFlow > .elFieldCopy3 > * { 
  margin-left: auto;
  width: 95px;
  margin-right: 54.4%;
  }
  


  .Tonnage_volume > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: -27px;
    margin-left: 50.0%;
    margin-right: 0px;
    width: calc(50.0% - 0px);
  }
  .Tonnage_volume > .layoutFlow > .elText > * { 
    width: 100%;
  }
  


  .Tonnage_volume > .layoutFlow > .elFieldCopy2 {
    position: relative;
    margin-top: 10px;
    width: 100%;
  }
  .Tonnage_volume > .layoutFlow > .elFieldCopy2 > * { 
  margin-left: auto;
  width: 95px;
  margin-right: 54.4%;
  }
  


  .Tonnage_volume > .layoutFlow > .elTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: -27px;
    margin-left: 50.0%;
    margin-right: 0px;
    width: calc(50.0% - 0px);
  }
  .Tonnage_volume > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Image_pav {
    min-height: 134px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 109px */
    height: 100%;
  }
  .Image_pav > .background > .containerMinHeight {
    min-height: 134px;
  }


  .Image_pav > .foreground > .state0_elImage {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Image_pav {
    min-height: 134px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 109px */
    height: 100%;
  }
  .Image_pav > .background > .containerMinHeight {
    min-height: 134px;
  }


  .Image_pav > .foreground > .state1_elImage {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Image_pav {
    min-height: 134px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 109px */
    height: 100%;
  }
  .Image_pav > .background > .containerMinHeight {
    min-height: 134px;
  }


  .Image_pav > .foreground > .state2_elImage {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Tag_anomalie {
    min-height: 20px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 150px */
    height: 100%;
  }
  .Tag_anomalie > .background > .containerMinHeight {
    min-height: 20px;
  }


  .Tag_anomalie > .foreground > .state0_elRectangle {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Tag_anomalie > .foreground > .state0_elText {
    overflow: hidden;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 26px;
  }

/* This component has a fixed-size layout */
  .Tag_anomalie {
    min-height: 20px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 150px */
    height: 100%;
  }
  .Tag_anomalie > .background > .containerMinHeight {
    min-height: 20px;
  }


  .Tag_anomalie > .foreground > .state1_elRectangle {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Tag_anomalie > .foreground > .state1_elText {
    overflow: hidden;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Tag_anomalie {
    min-height: 20px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 150px */
    height: 100%;
  }
  .Tag_anomalie > .background > .containerMinHeight {
    min-height: 20px;
  }


  .Tag_anomalie > .foreground > .state2_elRectangle {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Tag_anomalie > .foreground > .state2_elText {
    overflow: hidden;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Item_anomalie {
    min-height: 28px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 200px */
    height: 100%;
  }
  .Item_anomalie > .background > .containerMinHeight {
    min-height: 28px;
  }


  .Item_anomalie > .foreground > .state0_elCheckboxCopy {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 200px;
    height: 27px;
  }

.Item_anomalie .state0_elTag_anomalieCopy2 {
  overflow: hidden;
  pointer-events: auto;
}

.Item_anomalie .state0_elTag_anomalieCopy2.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Item_anomalie > .foreground > .state0_elTag_anomalieCopy2 {
    position: absolute;
    left: 23px;
    top: 2px;
    width: 150px;
    height: 20px;
  }

/* This component has a fixed-size layout */
  .Item_anomalie {
    min-height: 28px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 200px */
    height: 100%;
  }
  .Item_anomalie > .background > .containerMinHeight {
    min-height: 28px;
  }


  .Item_anomalie > .foreground > .state1_elCheckboxCopy {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 200px;
    height: 27px;
  }

.Item_anomalie .state1_elTag_anomalieCopy2 {
  overflow: hidden;
  pointer-events: auto;
}

.Item_anomalie .state1_elTag_anomalieCopy2.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Item_anomalie > .foreground > .state1_elTag_anomalieCopy2 {
    position: absolute;
    left: 23px;
    top: 2px;
    width: 150px;
    height: 20px;
  }

/* This component has a fixed-size layout */
  .Item_anomalie {
    min-height: 28px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 200px */
    height: 100%;
  }
  .Item_anomalie > .background > .containerMinHeight {
    min-height: 28px;
  }


  .Item_anomalie > .foreground > .state2_elCheckboxCopy {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 200px;
    height: 27px;
  }

.Item_anomalie .state2_elTag_anomalieCopy2 {
  overflow: hidden;
  pointer-events: auto;
}

.Item_anomalie .state2_elTag_anomalieCopy2.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Item_anomalie > .foreground > .state2_elTag_anomalieCopy2 {
    position: absolute;
    left: 23px;
    top: 2px;
    width: 150px;
    height: 20px;
  }

/* This component has a fixed-size layout */
  .Cross {
    min-height: 82px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 82px */
    height: 100%;
  }
  .Cross > .background > .containerMinHeight {
    min-height: 82px;
  }

.Cross .state0_elIconButton154802 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Cross .state0_elIconButton154802:focus {
  box-shadow: 0 0 6px rgba(255, 129, 120, 0.2500);
  outline: none;
}



  .Cross > .foreground > .state0_elIconButton154802 {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 80px;
    height: 80px;
  }

/* This component has a fixed-size layout */
  .Cross {
    min-height: 82px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 82px */
    height: 100%;
  }
  .Cross > .background > .containerMinHeight {
    min-height: 82px;
  }

.Cross .state1_elIconButton154802 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Cross .state1_elIconButton154802:focus {
  box-shadow: 0 0 6px rgba(255, 129, 120, 0.2500);
  outline: none;
}



  .Cross > .foreground > .state1_elIconButton154802 {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 80px;
    height: 80px;
  }

/* This component has a fixed-size layout */
  .Cross {
    min-height: 82px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 82px */
    height: 100%;
  }
  .Cross > .background > .containerMinHeight {
    min-height: 82px;
  }

.Cross .state2_elIconButton154802 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Cross .state2_elIconButton154802:focus {
  box-shadow: 0 0 6px rgba(255, 129, 120, 0.2500);
  outline: none;
}



  .Cross > .foreground > .state2_elIconButton154802 {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 80px;
    height: 80px;
  }

/* This component has a fixed-size layout */
  .Cross {
    min-height: 82px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 82px */
    height: 100%;
  }
  .Cross > .background > .containerMinHeight {
    min-height: 82px;
  }

.Cross .state3_elIconButton154802 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Cross .state3_elIconButton154802:focus {
  box-shadow: 0 0 6px rgba(255, 129, 120, 0.2500);
  outline: none;
}



  .Cross > .foreground > .state3_elIconButton154802 {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 80px;
    height: 80px;
  }

/* This component has a fixed-size layout */
  .Cross {
    min-height: 82px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 82px */
    height: 100%;
  }
  .Cross > .background > .containerMinHeight {
    min-height: 82px;
  }

.Cross .state4_elIconButton154802 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Cross .state4_elIconButton154802:focus {
  box-shadow: 0 0 6px rgba(255, 129, 120, 0.2500);
  outline: none;
}



  .Cross > .foreground > .state4_elIconButton154802 {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 80px;
    height: 80px;
  }

/* This component has a fixed-size layout */
  .Item_tri {
    min-height: 80px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 80px */
    height: 100%;
  }
  .Item_tri > .background > .containerMinHeight {
    min-height: 80px;
  }


  .Item_tri > .foreground > .elImage {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

.Item_tri .elCross {
  overflow: hidden;
  pointer-events: auto;
}

.Item_tri .elCross.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Item_tri > .foreground > .elCross {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 82px;
    height: 100%;
  }

